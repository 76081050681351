<template>
  <div>
    <div class="card-table-body ifon-add-title-block m-0 p-0 pl-3 pr-3">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="caz-blocks-sarcho-title">
            <div class="content-title d-flex align-center mr-2">
              {{ $t("message.protocol") }}
            </div>
          </div>
        </el-col>

        <el-col :span="12" class="flex-style text-right">
          <el-button
            type="success"
            size="small"
            @click="centerDialogVisible = true"
          >
            {{ $t("message.create") }}
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="m-3">
      <table
        class="table-my-code table-bordered p-2 bode r-left-none"
        :class="mode ? 'table__myday' : 'table__mynight'"
      >
        <thead>
          <tr>
            <th class="w50p" v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.workMeeting.show">
              {{ columns.workMeeting.title }}
            </th>
            <th v-if="columns.user.show">{{ columns.user.title }}</th>
            <th v-if="columns.participants.show">
              {{ columns.participants.title }}
            </th>
            <th v-if="columns.begin_time.show">
              {{ columns.begin_time.title }}
            </th>
            <th v-if="columns.end_time.show">{{ columns.end_time.title }}</th>
            <th v-if="columns.description.show">
              {{ columns.description.title }}
            </th>
            <th v-if="columns.conclusion.show">
              {{ columns.conclusion.title }}
            </th>
            <th v-if="columns.files.show">{{ columns.files.title }}</th>
            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>
            <!-- <th style="width: 200px">{{ $t("message.download") }}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="protocol in list" :key="'protocol-' + protocol.id">
            <td v-if="columns.id.show">
              {{ protocol.id }}
            </td>
            <td v-if="columns.workMeeting.show">
              {{ "№ " + protocol.file_protocolable_id }}
            </td>
            <td v-if="columns.user.show">
              {{ protocol.user ? protocol.user.name : "" }}
            </td>
            <td v-if="columns.participants.show">
              <el-button
                type="text"
                @click="showParticipants(protocol.participants)"
                style="margin: 0; padding: 0"
                >{{ $t("message.view") }}</el-button
              >
            </td>
            <td v-if="columns.begin_time.show">
              {{ protocol.begin_time }}
            </td>
            <td v-if="columns.end_time.show">
              {{ protocol.end_time }}
            </td>
            <td v-if="columns.description.show">
              <el-button
                type="text"
                @click="showText(protocol.description)"
                style="margin: 0; padding: 0"
                >{{ $t("message.view") }}</el-button
              >
            </td>
            <td v-if="columns.conclusion.show">
              <el-button
                type="text"
                @click="showText(protocol.conclusion)"
                style="margin: 0; padding: 0"
                >{{ $t("message.view") }}</el-button
              >
            </td>
            <td v-if="columns.files.show">
              <el-button
                type="text"
                @click="showFiles(protocol.files)"
                style="margin: 0; padding: 0"
                >{{ $t("message.view") }}</el-button
              >
            </td>
            <td v-if="columns.created_at.show">
              {{ protocol.created_at }}
            </td>
            <!-- <td>
					<span @click="showImage(pro_file.file)" style="cursor: pointer">{{
						pro_file.file_name
					}}</span>
					</td>
					<td>{{ pro_file.user ? pro_file.user.name : "" }}</td>
					<td>{{ pro_file.created_at }}</td>
					<td>
					<el-button
						@click="downloadFile(pro_file.file)"
						type="success"
						size="small"
						icon="el-icon-download"
						circle
					></el-button> </td>-->
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Create Protocol -->
    <el-dialog
      :title="$t('message.protocol') + ' № ' + work_meeting_id"
      :visible.sync="centerDialogVisible"
      width="70%"
      top="5vh"
      @opened="afterOpen"
      @closed="afterLeave"
    >
      <el-form ref="form" :model="model" :rules="rules" label-position="top">
        <el-row class="d-flex justify-between">
          <el-col :span="10">
            <div class="p-3 mt-3">
              <div
                class="main-user-check mb-3 mt-4"
                v-for="(user, index) in form.real_participants"
                :key="'user-' + index"
              >
                <div class="name-checker w-100 px-3" v-if="user.id">
                  <label :for="index">{{ user.name }}</label>
                  <input
                    type="checkbox"
                    :id="index"
                    :name="'checked-' + index"
                    :value="user.checked"
                    @change="checked(index, user.checked)"
                  />
                </div>
                <div class="name-delete w-100" v-else>
                  <el-input
                    :placeholder="$t('message.name')"
                    class="name-delete-input"
                    v-model="user.name"
                  >
                  </el-input>
                  <div class="name-delete-append">
                    <el-button
                      @click="deleteUser(index)"
                      type="danger"
                      size="small"
                      class="name-delete-button ml-2 mr-3"
                      icon="el-icon-delete"
                      circle
                    ></el-button
                    ><input
                      type="checkbox"
                      class="name-delete-check mr-3"
                      :id="index"
                      :name="'checked-' + index"
                      :value="user.checked"
                      @change="checked(index, user.checked)"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-center">
                <el-button type="primary" @click="addUser()" circle>
                  <i class="el-icon-circle-plus-outline"></i>
                </el-button>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="protocol_inform">
              <div class="d-flex f-between actually__time">
                <el-form-item :label="$t('message.from')" class="mr-2 w-100">
                  <el-time-select
                    :placeholder="$t('message.from')"
                    v-model="form.begin_time"
                    :picker-options="{
                      start: '08:30',
                      step: '00:15',
                      end: '18:30',
                    }"
                  >
                  </el-time-select>
                </el-form-item>
                <el-form-item :label="$t('message.before')" class="w-100">
                  <el-time-select
                    :placeholder="$t('message.before')"
                    v-model="form.end_time"
                    :picker-options="{
                      start: '08:30',
                      step: '00:15',
                      end: '18:30',
                      minTime: form.begin_time,
                    }"
                  >
                  </el-time-select>
                </el-form-item>
              </div>
              <div class="text">
                <el-form-item :label="$t('message.description')">
                  <ckeditor
                    :editor="editor"
                    v-model="form.description"
                    :config="editorConfig"
                  ></ckeditor>
                </el-form-item>
              </div>
              <div class="count_of_task">
                <h2>{{ $t("message.tasks") }}</h2>
                <div v-for="(task, index) in task_list" :key="'task-' + index">
                  <b>№</b> {{ task.id }}<br />
                  <b>{{ $t("message.name") + ": " }}</b> {{ task.name }} <br />
                  <b>{{ $t("message.user") + ": " }}</b> {{ task.user.name }}
                  <div v-if="task.all_participants.length > 0" class="mt-1">
                    <b>{{ $t("message.participants") }}</b>
                    <ul>
                      <li
                        v-for="participant in task.all_participants"
                        :key="participant.id"
                      >
                        * {{ participant.name }}
                      </li>
                    </ul>
                  </div>
                  <br />
                </div>
              </div>
              <div class="conclusion">
                <el-form-item class="conclusion-text" :label="$t('message.conclusion')">
                  <ckeditor
                    :editor="editor"
                    v-model="form.conclusion"
                    :config="editorConfig"
                  ></ckeditor>
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="file_upload">
              <el-form-item :label="$t('message.upload_file')">
                <el-upload
                  class="upload-protocol"
                  action="#"
                  :accept="fileAccepts"
                  :file-list="fileList"
                  :auto-upload="false"
                  :on-change="updateFileList"
                  :on-remove="handleRemove"
                  multiple
                  :limit="5"
                  :on-exceed="handleExceed"
                >
                  <el-button size="small" type="primary">{{
                    $t("message.upload_file")
                  }}</el-button>
                  <div slot="tip" class="el-upload__tip">
                    pdf|doc|docx files with a size less than 5 MB
                  </div>
                </el-upload>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="audio_rec"></div>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer d-flex justify-center">
        <el-button v-loading="loadingButton" type="primary" @click="submit()">
          {{ $t("message.save") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- End Create Protocol -->

    <!-- SHOW participants -->
    <el-dialog
      :title="$t('message.participants')"
      :visible.sync="participantsDialogVisible"
      width="50%"
    >
      <div>
        <ul>
          <li
            v-for="(participant, index) in participants"
            :key="'participant-' + index"
			class="mb-2"
          >
            {{ ++index + ") " + participant.name }}
          </li>
        </ul>
      </div>
    </el-dialog>
    <!-- end SHOW participants -->

    <!-- SHOW TEXT -->
    <el-dialog
      :title="$t('message.text_t')"
      :visible.sync="textDialogVisible"
      width="50%"
    >
      <div>
        <p v-html="text"></p>
      </div>
    </el-dialog>
    <!-- end SHOW TEXT -->

    <!-- SHOW Files @click="showImage(file)"-->
    <el-dialog
      :title="$t('message.files')"
      :visible.sync="dialogFilesVisible"
      width="80%"
      center
      :append-to-body="true"
      @closed="closedFilesDialog"
    >
      <el-row :gutter="20" v-if="fileList && fileList.length > 0">
        <el-col :span="12" v-for="file in fileList" :key="file.id">
          <div class="mb-2 d-flex" style="border: 1px solid #dee3e7">
            <div class="mm-image-container" style="cursor: pointer">
              <!-- <el-image v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes((/[^.]*$/.exec(file.name)[0]))"
							style="max-width:100%; max-height:100%; position: relative; top: 50%; transform: translateY(-50%);"
							class="p-1"
							:src="file.path"
							fit="fill">
						</el-image> -->
              <div class="text-center">
                <i
                  class="el-icon-document-copy mt-2"
                  style="font-size: 50px"
                ></i>
                <span class="d-block mt-2 font-weight-bold">{{
                  /[^.]*$/.exec(file.name)[0]
                }}</span>
              </div>
            </div>
            <div class="ml-2">
              <h3>{{ file.name }}</h3>
              <div class="d-flex">
                <!-- :download="file.name" target="_blank" file.path client_form_id, file.name, 'clientFormFiles'-->
                <a href="#" class="mr-3">
                  <el-button
                    type="primary"
                    @click="downloadFile(file)"
                    icon="el-icon-download"
                    size="medium"
                  ></el-button>
                </a>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- end SHOW Files -->

    <!-- <el-dialog
          v-if="selectedImage.name"
          :title="selectedImage.name"
          :append-to-body="true"
          width="85%"
          custom-class="mm-picture-show"
          :visible.sync="dialogPictureVisible"
		  >
          <div ref="ImageContainer" class="text-center w-100">
            <el-image v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes(selectedImage.type || (/[^.]*$/.exec(selectedImage.name)[0])) ||
                (selectedImage.raw && ['image/png', 'image/jpeg'].includes(selectedImage.raw.type))"
              style="object-fit: contain;"
              class="p-1"
              :src="selectedImage.url"
              fit="fill">
            </el-image>
          </div>
        </el-dialog> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export default {
  // name: 'GoCrmFrontedWorkMeetingDoc',
  data() {
    return {
      form: {},
      filterForm: {
        file_protocolable_type: "work_meetings",
        file_protocolable_id: this.$route.params.id,
      },
      selectedImage: {},
      isLoading: false,
      loadingButton: false,
      centerDialogVisible: false,
      participantsDialogVisible: false,
      textDialogVisible: false,
      dialogFilesVisible: false,
      editor: Editor,
      editorConfig: {},
      fileList: [],
      participants: [],
      text: "",
      fileAccepts:
        "application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      mode: "MODE",
      authUser: "auth/user",
      model: "fileProtocol/model",
      rules: "fileProtocol/rules",
      columns: "fileProtocol/columns",
      list: "fileProtocol/list",
      work_meeting: "workMeeting/model",
      tasks: "tasks/list",
    }),
    work_meeting_id() {
      if (this.$route.params) {
        return this.$route.params.id;
      }
      return null;
    },
    task_list() {
      if (!_.isEmpty(this.tasks)) {
        return this.tasks;
      }
      return [];
    },
  },
  watch: {},
  async created() {
    await this.updateFiles(this.filterForm);
  },

  methods: {
    ...mapActions({
      updateFiles: "fileProtocol/index",
      save: "fileProtocol/store",
    }),
    afterOpen() {
      this.form = JSON.parse(JSON.stringify(this.model));

      if (this.work_meeting.user) {
        this.form.real_participants.push({
          id: this.work_meeting.user.id,
          name: this.work_meeting.user.name,
          checked: false,
        });
      }

      if (
        this.work_meeting.responsibles &&
        !_.isEmpty(this.work_meeting.responsibles)
      ) {
        _.forEach(this.work_meeting.responsibles, (user) => {
          this.form.real_participants.push({
            id: user.id,
            name: user.name,
            checked: false,
          });
        });
      }

      if (
        this.work_meeting.participants &&
        !_.isEmpty(this.work_meeting.participants)
      ) {
        _.forEach(this.work_meeting.participants, (user) => {
          this.form.real_participants.push({
            id: user.id,
            name: user.name,
            checked: false,
          });
        });
      }
    },
    showParticipants(participants) {
      this.participantsDialogVisible = true;
      this.participants = participants;
    },
    showText(text) {
      this.textDialogVisible = true;
      this.text = text;
    },
    showFiles(files) {
      this.dialogFilesVisible = true;
      this.fileList = files;
    },
    checked(index, status) {
      this.$set(this.form.real_participants[index], "checked", !status);
    },
    updateFileList(file, fileList) {
      if (file.size > 5242880) {
        fileList.pop();
        return this.$message({
          type: "warning",
          message: this.$t("message.file_size_limit_warning", { number: 5 }),
        });
      }

      if (!_.find(this.fileList, (o) => o.name == file.name)) {
        this.fileList.push(file.raw);
      } else fileList.pop();
    },
    handleRemove(file, fileList) {
      let index = _.findIndex(this.fileList, { name: file.name });
      this.fileList.splice(index, 1);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        this.$t("message.file_limit_warning", { number: 5 })
      );
    },
    addUser() {
      this.form.real_participants.push({ id: null, name: "", checked: false });
    },
    deleteUser(index) {
      if (index) this.form.real_participants.splice(index, 1);
    },
    async handleFileDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;

      if (files.length > 0) {
        this.isLoading = true; // Show the loader while uploading

        const file = files[0];
        try {
          await this.handleFileUpload(file);
          this.isLoading = false; // Hide the loader after uploading
        } catch (error) {
          console.error("File upload error:", error);
          this.isLoading = false; // Hide the loader in case of an error
        }
      }
    },
    handleFileUpload(file) {
      return new Promise((resolve, reject) => {
        const isImage = file.type.startsWith("image/");
        const dropArea = document.getElementById("dropArea");
        this.form.file = file;
        this.form.file.url = URL.createObjectURL(file);
        this.form.file_name = this.getFileNameWithoutExtension(file.name);
        this.form.file_extension = this.getFileExtension(file.name);
        if (isImage) {
          dropArea.style.backgroundImage = `url(${this.form.file.url})`;
          resolve();
        } else {
          dropArea.style.backgroundImage = `url(../img/all-files.jpg)`;
          resolve(); // Resolve the promise for non-image files
        }
      });
    },
    openFileInput() {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.style.display = "none";
      fileInput.addEventListener("change", (event) => {
        const files = event.target.files;

        if (files.length > 0) {
          this.handleFileUpload(files[0]);
        }
      });
      document.body.appendChild(fileInput);
      fileInput.click();
      document.body.removeChild(fileInput);
    },
    getFileNameWithoutExtension(fileName) {
      const lastIndex = fileName.lastIndexOf(".");
      if (lastIndex !== -1) {
        return fileName.substring(0, lastIndex);
      } else {
        return fileName;
      }
    },
    getFileExtension(fileName) {
      const lastIndex = fileName.lastIndexOf(".");
      if (lastIndex !== -1) {
        return fileName.substring(lastIndex, fileName.length);
      } else {
        return fileName;
      }
    },
    submit() {
      this.form.user_id = this.authUser.id;
      this.form.file_protocolable_type = "work_meetings";
      this.form.file_protocolable_id = this.work_meeting_id;
      this.form.real_participants = _.filter(
        this.form.real_participants,
        "checked"
      );
      //   this.form.file_name = this.form.file_name + this.form.file_extension;
      this.loadingButton = true;
      let formData = new FormData();
      for (const key in this.form) {
        if (key != "real_participants") {
          if (this.form[key]) {
            formData.append(key, this.form[key]);
          } else {
            formData.append(key, "");
          }
        } else if (key == "real_participants") {
          formData.append(key, JSON.stringify(this.form[key]));
        }
      }

      if (!_.isEmpty(this.fileList)) {
        for (const key in this.fileList) {
          if (this.fileList.hasOwnProperty(key)) {
            const element = this.fileList[key];
            formData.append(`fileList[${key}]`, element);
          }
        }
      }

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.save(formData)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.centerDialogVisible = false;
              this.updateFiles(this.filterForm);
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },

    showImage(file) {
      if (file.name) {
        if (
          ["image/png", "image/jpeg", "jpeg", "jpg", "png"].includes(
            file.type || /[^.]*$/.exec(file.name)[0]
          ) ||
          (file.raw && ["image/png", "image/jpeg"].includes(file.raw.type))
        ) {
          this.selectedImage = file;
          this.dialogPictureVisible = true;
        } else {
          this.downloadFile(file);
        }
      }
    },
    downloadFile(file) {
      let link = document.createElement("a");
      link.href = file.path;
      link.target = "_blank";
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closedFilesDialog() {
      this.fileList = [];
    },
    afterLeave() {
      this.$store.commit("fileProtocol/EMPTY_MODEL");
      this.fileList = [];
      this.form.real_participants = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.main-user-check {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}


.main-user-check .name-checker {
  border: 1px #4444442c solid;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
  }
  input {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}
.count_of_task {
  line-height: 160%;
}

.main-user-check .name-delete {
  border: 1px #4444442c solid;
  //   border-left-style: none;
  border-radius: 4px;
  display: flex;
  align-self: start;

  &-append {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-button {
    cursor: pointer;
  }

  &-check {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}
.boder-left-none th,
.boder-left-none td {
  border-left: none !important;
  border-right: none !important;
}
#dropArea {
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #555;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.mm_dropArea_innner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>