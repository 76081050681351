<template>
    <div class="workMeetingInfo">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="caz-blocks-sarcho-title">
                <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.workMeeting") }} </div>
                <div class="block-sarche">
                  <div class="header__search">
                    
                     <crm-input
                          :size="'small'"
                          :class="mode ? 'input__day' : 'input__night'"
                          :className="'w100'"
                          v-model="filterForm.search"
                          :icon="'el-icon-search'"
                      ></crm-input>
                  </div>
                </div>
              </div>
            </el-col>
  
            
          </el-row>
        </div>
        <!-- end ifon-add-title-block -->
  
        <div class="table-my-code table-bordered"  >
            <el-tabs v-model="editableTabsValue">
                <el-tab-pane  :label="$t('message.workMeeting')" name="first">
                    <WorkMeetingInfo></WorkMeetingInfo>
                </el-tab-pane>
                <el-tab-pane  :label="$t('message.task')" name="second">
                    <workMeetingTask></workMeetingTask>
                </el-tab-pane>
                <el-tab-pane  :label="$t('message.protocol')" name="third">
                    <WorkMeetingDoc></WorkMeetingDoc>
                </el-tab-pane>
            </el-tabs>
        </div>
      </div>
    </div>
</template>
  
<script>
	import { mapGetters, mapActions } from "vuex";
	import WorkMeetingInfo from './components/workMeetingInfo.vue';
	import WorkMeetingDoc from './components/workMeetingDoc.vue';
	import workMeetingTask from './components/workMeetingTask.vue';

   export default {
		name: "workMeeting",
		components: {
			WorkMeetingInfo,
			WorkMeetingDoc,
			workMeetingTask
		},

    data() {
        return {
          editableTabsValue: 'first',
          filterForm: {
            search: ''
          }
        };
    },

    computed: {
        ...mapGetters({
            mode: "MODE"
        }),
      },
    methods: {
        ...mapActions({
        }),
        
    },
   };
</script>

<style lang="scss">
   .workMeetingInfo{
      .el-tabs__nav .el-tabs__item{
          padding: 10px !important;
          height: 50px;
      }
  }
</style>
  